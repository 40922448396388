<template>
   <div class="h-screen items-center justify-center text-center grid">
      <div class="p-5 flex flex-col justify-center text-center items-center">
         <img src="../assets/img-404.svg" class="animate-bounce" alt="">
         <span class="font-bold text-4xl mt-5 mb-10">SORRY, PAGE NOT FOUND</span>
         <span class="bg-yellow rounded-10 font-medium text-base text-black block px-5 py-2 whitespace-nowrap cursor-pointer"
            @click="back"
         >
            Go Back
         </span>
      </div>
   </div>
</template>

<script>
export default {
   name: 'Custom404',
   methods: {
      back(){
        this.$router.go(-1); 
      },
      redirectTime() {
         setTimeout(() => {
            this.back();
         }, 3000);
      }
   },
   created() {
      // this.redirectTime();
   }
}
</script>